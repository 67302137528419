.header {
  background-color: #2F4438;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.logo {
    fill: #A4AEB1;
}
#logo_svg {
    max-width: 100%;
    height: auto;
}
.header-nav {
  font-size: calc(1.5vmin);
}
